import React, { useState } from "react";
import "./dashboard.scss";
import { RiShutDownLine } from "react-icons/ri";
import { CgBot } from "react-icons/cg";
import { IoIosArrowDown } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  signOutUserStart,
  signOutUserFailure,
  signOutUserSuccess,
} from "../../redux/userSlice";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import DateTime from "../../components/sidebar/DateTime";
import UserIcon from "../../components/sidebar/UserIcon";
import MonthlyWeeklyView from "../../components/sidebar/MonthlyWeeklyView";
import Segments from "../../components/sidebar/Segments";
import View from "../../components/dashboard/View";
import CallSegmentChart from "../../components/dashboard/charts/CallSegmentChart";
import DistributionBySegmentChart from "../../components/dashboard/charts/DistributionBySegmentChart";
import AverageCallsChart from "../../components/dashboard/charts/AverageCallsChart";
import RecoveryDistributionChart from "../../components/dashboard/charts/RecoveryDistributionChart";
import AmountRecoveryBySegmentsDPD from "../../components/dashboard/charts/AmountRecoveryBySegmentsDPD";
import { useEffect } from "react";
import { useRef } from "react";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [runId, setRunId] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [view, setView] = useState("Select");
  const [segment, setSegment] = useState("Select");
  const chatEndRef = useRef(null);

  const handleSignOut = async () => {
    try {
      dispatch(signOutUserStart());
      const res = await axios.post(`${SERVER_URL}/user/logout`, {
        id: currentUser?._id,
      });
      const data = await res.data;
      if (!data || res.status === 500) {
        dispatch(signOutUserFailure(data.message));
        return;
      }
      dispatch(signOutUserSuccess(data));
      localStorage.clear();
      Cookies.remove("access_token");
      <Navigate to="/login" />;
    } catch (error) {
      dispatch(signOutUserFailure(error.message));
    }
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const sendMessage = async () => {
    if (!message.trim()) return;

    try {
      const res = await axios.post(
        "https://dasceqchatbotapi.dasceq.com/send_message/",
        { user_message: message },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { thread_id, run_id } = res.data;
      setThreadId(thread_id);
      setRunId(run_id);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { user: "You", text: message },
      ]);
      setMessage("");
      setIsPolling(true);
    } catch (error) {
      console.error("Error sending message:", error);
      setError("Failed to send message");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  useEffect(() => {
    let intervalId;

    const fetchResponse = async () => {
      console.log("Inside fetchResponse function.");
      console.log("Current state:", { threadId, runId, isPolling });

      if (threadId && runId && isPolling) {
        try {
          const res = await axios.get(
            `https://dasceqchatbotapi.dasceq.com/get_response/?thread_id=${threadId}&run_id=${runId}`
          );
          const { response } = res.data;

          if (response) {
            setChatMessages((prevMessages) => [
              ...prevMessages,
              { user: "DASCEQ AI", text: response },
            ]);
          }

          console.log("Response completed. Clearing states.");
          setThreadId(null);
          setRunId(null);
          setIsPolling(false);

          console.log("State after update:", { threadId, runId, isPolling });

          clearInterval(intervalId);
        } catch (error) {
          console.error("Error fetching response:", error);
          setIsPolling(false);

          console.log("State after error:", { threadId, runId, isPolling });

          clearInterval(intervalId);
        }
      }
    };

    if (isPolling) {
      console.log("Starting polling interval.");
      intervalId = setInterval(fetchResponse, 5000);
    }

    return () => {
      console.log("Cleaning up interval.");
      clearInterval(intervalId);
    };
  }, [threadId, runId, isPolling]);

  useEffect(() => {
    console.log("State updated:", { threadId, runId, isPolling });
  }, [threadId, runId, isPolling]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const isSelectionMade = view !== "Select" && segment !== "Select";

  return (
    <div className="dashboard-container">
      <div className="left-sidebar">
        <div className="action-group">
          <div className="user-icon-container">
            <UserIcon />
          </div>
          <hr className="separator" />
          <div className="date-time-container">
            <DateTime />
          </div>
          <div className="view">
            <MonthlyWeeklyView view={view} setView={setView} />
          </div>
          <div className="segments">
            <Segments segment={segment} setSegment={setSegment} />
          </div>
        </div>
      </div>
      <div className="right-group">
        <div className="top-header-container">
          <h1>
            Collection Manager <br />
            Dashboard
          </h1>
          <button className="btn-logout" onClick={handleSignOut}>
            <RiShutDownLine className="icon" /> Logout
          </button>
        </div>
        {view !== "Select" && segment !== "Select" && (
          <div className="recommendation">
            <View view={view} segment={segment} />
          </div>
        )}
        {!isSelectionMade && (
          <div className="no-selection-message">
            <h1>Please select the view and segment to draw the chart.</h1>
          </div>
        )}
        {isSelectionMade && (
          <>
            <div className="chart-container">
              <div className="call-segment-chart-container">
                <h3>No. of Calls by Segments</h3>
                <CallSegmentChart view={view} segment={segment} />
              </div>
              <div className="distribution-by-segment-container">
                <h3>Amount Recovery Distribution by Segments</h3>
                <DistributionBySegmentChart view={view} segment={segment} />
              </div>
              <div className="average-calls-container">
                <h3>Average No. of Calls by DPD</h3>
                <AverageCallsChart view={view} segment={segment} />
              </div>
              <div className="recovery-distribution-container">
                <h3>Amount Recovery Distribution by Delinquency Days</h3>
                <RecoveryDistributionChart view={view} segment={segment} />
              </div>
            </div>
            <div className="amount-recovery-segments-dpd">
              <h3>Amount Recovery by DPD & Segments</h3>
              <AmountRecoveryBySegmentsDPD view={view} segment={segment} />
            </div>
          </>
        )}
      </div>
      <div className="chatbot-icon" onClick={toggleChat}>
        <CgBot className="chat-icon" />
      </div>
      {isChatOpen && (
        <div className="chat-popup">
          <div className="chat-popup-header">
            <div className="chat-popup-heading-container">
              <CgBot className="chat-popup-header-icon" />
              <div className="popup-header-heading">
                <p>chat with</p>
                <h1>Dasceq AI</h1>
              </div>
            </div>
            <button className="close-btn" onClick={toggleChat}>
              <IoIosArrowDown />
            </button>
          </div>
          <div className="chat-popup-body">
            <div className="chat-popup-body-top">
              <p>We typically reply in few moments</p>
            </div>
            <div className="chat-popup-body-chat-container">
              {chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`chat-message ${
                    msg.user === "You" ? "user-message" : "ai-message"
                  }`}
                >
                  <strong>{msg.user}: </strong>
                  {msg.text}
                </div>
              ))}
              <div ref={chatEndRef} />
            </div>
            <div className="chat-popup-body-bottom">
              <form
                method="POST"
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <input
                  type="text"
                  id="message"
                  name="message"
                  placeholder="Enter your message..."
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button type="submit">
                  <IoSend className="send-icon" />
                </button>
              </form>
              {error && (
                <p
                  style={{
                    color: "red",
                    display: "flex",
                    width: "100%",
                    margin: 0,
                    textAlign: "center",
                    justifyContent: "center",
                    padding: "10px 0px",
                  }}
                >
                  {error}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
