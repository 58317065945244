// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call-segment-chart-details {
  width: 100%;
  height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/charts/callsegmentchart.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF","sourcesContent":[".call-segment-chart-details {\n  width: 100%;\n  height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
