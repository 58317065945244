import React from "react";
import "./viewdetails.scss";
import PredictedSegment from "./PredictedSegment";
import PaymentBehavior from "./PaymentBehavior";
import PaymentAccuracy from "./PaymentAccuracy";

const ViewDetails = ({view, segment}) => {
  return (
    <div className="view-details">
      <PredictedSegment />
      <PaymentBehavior />
      <PaymentAccuracy view={view} segment={segment} />
    </div>
  );
};

export default ViewDetails;
