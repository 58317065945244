import React, { useEffect, useState } from "react";
import "./callsegmentchart.scss";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { SERVER_URL } from "../../../utils/helper";
import { format, parseISO, isValid } from "date-fns";

const CallSegmentChart = ({ view, segment }) => {
  const [data, setData] = useState([]);

  const segmentMap = {
    "Segment 1": "Seg_1",
    "Segment 2": "Seg_2",
    "Segment 3": "Seg_3",
    "Segment 4": "Seg_4",
  };

  const apiSegment = segmentMap[segment] || "Select";

  useEffect(() => {
    if (view !== "Select" && apiSegment !== "Select") {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `${SERVER_URL}/call/chart-one/${apiSegment}/${view}`
          );
          const fetchedData = res.data;

          const transformedData = fetchedData.map((item) => {
            let formattedDate = item.CallDate;
            if (view === "Weekly") {
              try {
                const parsedDate = parseISO(item.CallDate);
                if (isValid(parsedDate)) {
                  formattedDate = format(parsedDate, "MM-dd");
                }
              } catch (error) {
                console.error("Error parsing date:", error);
              }
            }
            return { ...item, CallDate: formattedDate };
          });

          setData(transformedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [view, apiSegment]);

  const numberFormatter = (value) => new Intl.NumberFormat().format(value);

  const xAxisLabel = view === "Weekly" ? "Weekly" : "Monthly";

  return (
    <div className="call-segment-chart-details">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width="100%"
          height="100%"
          data={data}
          margin={{
            top: 5,
            right: 30,
            bottom: 10,
            left: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="CallDate"
            angle={-45}
            textAnchor="end"
            interval={0}
            tick={{ fontSize: 10 }}
            height={60}
            label={{
              value: xAxisLabel,
              position: "insideBottom",
              offset: -2,
              style: { textAnchor: "middle", fontSize: 14 },
            }}
          />
          <YAxis
            tick={{ fontSize: 10 }}
            tickFormatter={numberFormatter}
            label={{
              value: "Number of Calls",
              angle: -90,
              position: "insideLeft",
              dx: -20,
              style: { textAnchor: "middle", fontSize: 14 },
            }}
          />
          <Tooltip />
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="top"
            wrapperStyle={{
              paddingBottom: 10,
              marginLeft: 25,
            }}
            content={({ payload }) => (
              <div className="custom-legend">
                {payload.map((entry) => (
                  <div
                    key={entry.value}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 10px",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 12,
                        height: 12,
                        backgroundColor: entry.color,
                        marginRight: 5,
                        borderRadius: 2,
                      }}
                    ></div>
                    <span>{entry.value}</span>
                  </div>
                ))}
              </div>
            )}
          />
          <Line
            type="monotone"
            dataKey="NumCalls"
            stroke="#003399"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CallSegmentChart;
