// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  width: 100%;
  height: 40px;
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-shadow: #fdd6f1 0px 5px 15px;
}
.input-field input {
  flex: 1 1;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 5px;
  font-size: 18px;
}
.input-field .icon {
  font-size: 25px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/password-input/passwordInput.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,gCAAA;AACF;AACE;EACE,SAAA;EACA,YAAA;EACA,6BAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AACJ;AAEE;EACE,eAAA;EACA,eAAA;AAAJ","sourcesContent":[".input-field {\n  width: 100%;\n  height: 40px;\n  background-color: #fff;\n  border-radius: 24px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 15px;\n  box-shadow: #fdd6f1 0px 5px 15px;\n\n  input {\n    flex: 1;\n    height: 100%;\n    background-color: transparent;\n    outline: none;\n    border: none;\n    padding: 5px;\n    font-size: 18px;\n  }\n\n  .icon {\n    font-size: 25px;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
