// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0;
}

.date {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/datetime.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,SAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,SAAA;AACJ","sourcesContent":[".time{\n    font-size: 50px;\n    font-weight: 700;\n    letter-spacing: 1px;\n    margin: 0;\n}\n\n.date{\n    font-size: 18px;\n    font-weight: 500;\n    letter-spacing: 1px;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
