import React, { useEffect, useState } from "react";
import "./amountrecoverybysegmentsdpd.scss";
import { SERVER_URL } from "../../../utils/helper";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const AmountRecoveryBySegmentsDPD = ({ view, segment }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (view !== "Select" && segment !== "Select") {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${SERVER_URL}/payment/chart-five`, {
            params: {
              clientID: segment, // Assuming 'segment' represents clientID
              kpiType: view, // Assuming 'view' represents KPIType
            },
          });
          const processedData = processData(response.data);
          setData(processedData);
        } catch (error) {
          console.error("Unable to fetch data!", error.message);
        }
      };
      fetchData();
    }
  }, [view, segment]);

  const processData = (rawData) => {
    const result = {};

    rawData.forEach((item) => {
      const { PaymentDate, Campaign_name, DPDGroup, TransactionAmount } = item;

      const week = new Date(PaymentDate).toISOString().split("T")[0];

      if (!result[week]) {
        result[week] = {
          week,
          "1-15_Seg_1": 0,
          "16-30_Seg_1": 0,
          "31-60_Seg_1": 0,
          "1-15_Seg_2": 0,
          "16-30_Seg_2": 0,
          "31-60_Seg_2": 0,
          "1-15_Seg_3": 0,
          "16-30_Seg_3": 0,
          "31-60_Seg_3": 0,
          "1-15_Seg_4": 0,
          "16-30_Seg_4": 0,
          "31-60_Seg_4": 0,
        };
      }

      const key = `${DPDGroup}_${Campaign_name}`;
      if (result[week][key] !== undefined) {
        result[week][key] += TransactionAmount;
      }
    });

    return Object.values(result);
  };

  return (
    <div className="grouped-stacked-bar-chart-container">
      <ResponsiveContainer width="100%" height={440}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{ top: 20, right: 200, left: 60, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            tickFormatter={(value) => `$${value.toLocaleString()}`}
            label={{
              value: "Amount Recovered",
              position: "insideBottom",
              offset: -10,
              style: { fontSize: "14px" },
            }}
            tick={{ fontSize: "10px" }}
          />
          <YAxis
            type="category"
            dataKey="week"
            label={{
              value: "Monthly",
              angle: -90,
              position: "insideLeft",
              offset: -20,
              style: { fontSize: "14px" },
            }}
            tick={{ fontSize: "10px" }}
          />
          <Tooltip />
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            wrapperStyle={{
              position: "absolute",
              right: 0,
              top: 0,
              height: "100%",
              width: 200,
              overflow: "auto",
            }}
          />
          <Bar dataKey="1-15_Seg_1" stackId="a" fill="#0097B2" />
          <Bar dataKey="16-30_Seg_1" stackId="a" fill="#0CC0DF" />
          <Bar dataKey="31-60_Seg_1" stackId="a" fill="#5CE1E6" />
          <Bar dataKey="1-15_Seg_2" stackId="b" fill="#38B6FF" />
          <Bar dataKey="16-30_Seg_2" stackId="b" fill="#5271FF" />
          <Bar dataKey="31-60_Seg_2" stackId="b" fill="#003399" />
          <Bar dataKey="1-15_Seg_3" stackId="c" fill="#C1FF72" />
          <Bar dataKey="16-30_Seg_3" stackId="c" fill="#7ED957" />
          <Bar dataKey="31-60_Seg_3" stackId="c" fill="#00BF63" />
          <Bar dataKey="1-15_Seg_4" stackId="d" fill="#7EFF93" />
          <Bar dataKey="16-30_Seg_4" stackId="d" fill="#8C52FF" />
          <Bar dataKey="31-60_Seg_4" stackId="d" fill="#5E17EB" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AmountRecoveryBySegmentsDPD;
