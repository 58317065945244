import React from "react";
import "./monthlyweeklyview.scss";

const MonthlyWeeklyView = ({ view, setView }) => {
  const handleChange = (e) => {
    setView(e.target.value);
  };

  return (
    <div className="monthly-weekly-view-details">
      <h1>monthly/ weekly view</h1>
      <select name="view" id="view" value={view} onChange={handleChange}>
        <option value="Select">Select</option>
        <option value="Monthly">Monthly</option>
        <option value="Weekly">Weekly</option>
      </select>
    </div>
  );
};

export default MonthlyWeeklyView;
