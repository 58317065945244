import React from "react";
import "./segments.scss";

const Segments = ({ segment, setSegment }) => {
  const handleChange = (e) => {
    setSegment(e.target.value);
  };

  return (
    <div className="segment-details">
      <h1>segments</h1>
      <select
        name="segment"
        id="segment"
        value={segment}
        onChange={handleChange}
      >
        <option value="Select">Select</option>
        <option value="Segment 1">Segment 1</option>
        <option value="Segment 2">Segment 2</option>
        <option value="Segment 3">Segment 3</option>
        <option value="Segment 4">Segment 4</option>
      </select>
    </div>
  );
};

export default Segments;
