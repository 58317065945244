// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.predicted-segment-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1;
}
.predicted-segment-container p {
  color: #fff;
}
.predicted-segment-container p span {
  color: #fff;
  font-weight: 700;
}
.predicted-segment-container h3 {
  margin: 0;
  color: #fff;
}
.predicted-segment-container h1 {
  color: #fff;
  font-size: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/predictedsegment.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,SAAA;AACF;AACE;EACE,WAAA;AACJ;AACI;EACE,WAAA;EACA,gBAAA;AACN;AAGE;EACE,SAAA;EACA,WAAA;AADJ;AAIE;EACE,WAAA;EACA,eAAA;AAFJ","sourcesContent":[".predicted-segment-container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  flex: 1;\n\n  p {\n    color: #fff;\n\n    span {\n      color: #fff;\n      font-weight: 700;\n    }\n  }\n\n  h3 {\n    margin: 0;\n    color: #fff;\n  }\n\n  h1 {\n    color: #fff;\n    font-size: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
