import React, { useEffect, useState } from "react";
import "./emailInput.scss";
import { Icon } from "react-icons-kit";
import { info } from "react-icons-kit/icomoon/info";
import { cancelCircle } from "react-icons-kit/icomoon/cancelCircle";
import { checkmark } from "react-icons-kit/icomoon/checkmark";

const EmailInput = ({
  name,
  id,
  type,
  placeholder,
  autoComplete,
  value,
  onChange,
}) => {
  const [icon, setIcon] = useState(info);
  const [iconColor, setIconColor] = useState("hsl(30, 100%, 50%)");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (email.length > 0) {
      if (email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        setIcon(checkmark);
        setIconColor("hsl(120, 100%, 50%)");
      } else {
        setIcon(cancelCircle);
        setIconColor("hsl(0, 100%, 50%)");
      }
    } else {
      setIcon(info);
      setIconColor("hsl(30, 100%, 50%)");
    }
  }, [email]);

  const handleChange = (event) => {
    setEmail(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="input-field">
      <input
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        autoComplete={autoComplete}
        value={value}
        onChange={handleChange}
        required
      />
      <span>
        <Icon icon={icon} size={25} style={{ color: iconColor }} />
      </span>
    </div>
  );
};

export default EmailInput;
