// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-behavior-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1;
  padding-left: 20px;
}
.payment-behavior-container p {
  color: #fff;
  margin: 0;
  text-transform: capitalize;
}
.payment-behavior-container h1 {
  color: #fff;
  font-size: 36px;
  margin: 0;
  text-transform: capitalize;
}
.payment-behavior-container span {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/paymentbehavior.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,SAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,SAAA;EACA,0BAAA;AACJ;AAEE;EACE,WAAA;EACA,eAAA;EACA,SAAA;EACA,0BAAA;AAAJ;AAGE;EACE,WAAA;EACA,yBAAA;EACA,eAAA;AADJ","sourcesContent":[".payment-behavior-container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  flex: 1;\n  padding-left: 20px;\n\n  p {\n    color: #fff;\n    margin: 0;\n    text-transform: capitalize;\n  }\n\n  h1 {\n    color: #fff;\n    font-size: 36px;\n    margin: 0;\n    text-transform: capitalize;\n  }\n\n  span {\n    color: #fff;\n    text-transform: uppercase;\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
