// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-container {
  display: flex;
  flex-direction: column;
}
.view-container .icon {
  width: 30px;
  height: 30px;
  align-self: center;
  cursor: pointer;
  color: #003399;
}
.view-container .view-controller {
  display: flex;
  flex-direction: column;
}
.view-container .view-controller .heading {
  background: #096bfd;
  padding: 10px 0px;
}
.view-container .view-controller .heading .heading-wrapper {
  display: flex;
  justify-content: space-between;
  background: #fff;
  color: #003399;
}
.view-container .view-controller .heading .heading-wrapper h4 {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  text-transform: uppercase;
  color: #003399;
  margin-left: 40px;
}
.view-container .view-details-container {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/view.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;AAEI;EACE,mBAAA;EACA,iBAAA;AAAN;AAEM;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,cAAA;AAAR;AAEQ;EACE,aAAA;EACA,SAAA;EACA,sBAAA;EACA,yBAAA;EACA,cAAA;EACA,iBAAA;AAAV;AAME;EACE,aAAA;EACA,8BAAA;AAJJ","sourcesContent":[".view-container {\n  display: flex;\n  flex-direction: column;\n\n  .icon {\n    width: 30px;\n    height: 30px;\n    align-self: center;\n    cursor: pointer;\n    color: #003399;\n  }\n\n  .view-controller {\n    display: flex;\n    flex-direction: column;\n\n    .heading {\n      background: #096bfd;\n      padding: 10px 0px;\n\n      .heading-wrapper {\n        display: flex;\n        justify-content: space-between;\n        background: #fff;\n        color: #003399;\n\n        h4 {\n          display: flex;\n          flex: 1;\n          flex-direction: column;\n          text-transform: uppercase;\n          color: #003399;\n          margin-left: 40px;\n        }\n      }\n    }\n  }\n\n  .view-details-container {\n    display: flex;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
