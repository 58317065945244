import React from 'react';
import './paymentbehavior.scss'

const PaymentBehavior = () => {
  return (
    <div className="payment-behavior-container">
      <p>predicted payment behavior</p>
      <h1>moderate payer</h1>
      <p>Treatment for Early Cycle**</p>
      <span>Medium Phone Touch</span>
    </div>
  );
}

export default PaymentBehavior