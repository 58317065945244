import React from "react";
import "./usericon.scss";
import { FaUserCircle } from "react-icons/fa";

const UserIcon = () => {
  return (
    <>
      <FaUserCircle className="icon" />
    </>
  );
};

export default UserIcon;
