import React, { useEffect, useState } from "react";
import "./paymentaccuracy.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const PaymentAccuracy = ({ view, segment }) => {
  const [totalAmount, setTotalAmount] = useState(0);

  const segmentMap = {
    "Segment 1": "Seg_1",
    "Segment 2": "Seg_2",
    "Segment 3": "Seg_3",
    "Segment 4": "Seg_4",
  };
  const apiSegment = segmentMap[segment] || "Select";

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    if (view !== "Select" && apiSegment !== "Select") {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${SERVER_URL}/payment/chart-two/${apiSegment}/${view}`
          );
          const transactions = response.data;
          const total = transactions.reduce(
            (accumulator, transaction) =>
              accumulator + transaction.TransactionAmount,
            0
          );
          setTotalAmount(total);
        } catch (error) {
          console.error("Unable to fetch data!", error.message);
        }
      };
      fetchData();
    }
  }, [view, apiSegment]);

  return (
    <div className="prediction-accuracy-container">
      <p>Total Amount Recovered</p>
      <h2>{currencyFormatter.format(totalAmount)}</h2>
    </div>
  );
};

export default PaymentAccuracy;
