import React, { useState, useEffect } from "react";
import "./averagecallschart.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { format, parseISO, isValid } from "date-fns";
import { SERVER_URL } from "../../../utils/helper";

const AverageCallsChart = ({ view, segment }) => {
  const [data, setData] = useState([]);

  const segmentMap = {
    "Segment 1": "Seg_1",
    "Segment 2": "Seg_2",
    "Segment 3": "Seg_3",
    "Segment 4": "Seg_4",
  };

  const apiSegment = segmentMap[segment] || "Select";

  useEffect(() => {
    if (view !== "Select" && apiSegment !== "Select") {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `${SERVER_URL}/call/chart-three/${apiSegment}/${view}`
          );
          const fetchedData = res.data;

          const transformedData = fetchedData.reduce((acc, curr) => {
            let formattedDate = curr.CallDate;
            if (view === "Weekly") {
              try {
                const parsedDate = parseISO(curr.CallDate);
                if (isValid(parsedDate)) {
                  formattedDate = format(parsedDate, "MM-dd");
                }
              } catch (error) {
                console.error("Date parsing error:", error);
              }
            } else if (view === "Monthly") {
              try {
                const parsedDate = parseISO(curr.CallDate);
                if (isValid(parsedDate)) {
                  formattedDate = format(parsedDate, "MMMM-yyyy");
                }
              } catch (error) {
                console.error("Date parsing error:", error);
              }
            }

            const existingEntry = acc.find(
              (entry) => entry.CallDate === formattedDate
            );

            if (existingEntry) {
              existingEntry[curr.DPDGroup] = curr.AvgCalls;
            } else {
              acc.push({
                CallDate: formattedDate,
                [curr.DPDGroup]: curr.AvgCalls,
              });
            }
            return acc;
          }, []);

          setData(transformedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [view, apiSegment]);

  const xAxisLabel = view === "Weekly" ? "Weekly" : "Monthly";

  return (
    <div className="average-call-chart-details">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 50,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="CallDate"
            angle={-45}
            textAnchor="end"
            interval={0}
            tick={{ fontSize: 10 }}
            height={60}
            tickFormatter={(value) => {
              if (view === "Monthly") {
                const [year, month] = value.split("-");
                return `${month}-${year}`;
              }
              return value;
            }}
            label={{
              value: xAxisLabel,
              position: "insideBottom",
              offset: -10,
              style: { textAnchor: "middle", fontSize: 14 },
            }}
          />
          <YAxis
            tick={{ fontSize: 10 }}
            label={{
              value: "Average Number of Calls",
              angle: -90,
              position: "insideLeft",
              dx: -10,
              style: { textAnchor: "middle", fontSize: 14 },
            }}
          />
          <Tooltip />
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="top"
            wrapperStyle={{
              paddingBottom: 20,
              marginLeft: 30,
            }}
          />
          <Bar dataKey="1-15" stackId="a" fill="#096bfd" />
          <Bar dataKey="16-30" stackId="a" fill="#49c3fb" />
          <Bar dataKey="31-60" stackId="a" fill="#003399" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AverageCallsChart;
