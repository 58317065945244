import React, { useEffect, useState } from "react";
import "./recoverydistributionchart.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { format, parseISO, isValid } from "date-fns";
import { SERVER_URL } from "../../../utils/helper";

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

const RecoveryDistributionChart = ({ view, segment }) => {
  const [data, setData] = useState([]);

  const segmentMap = {
    "Segment 1": "Seg_1",
    "Segment 2": "Seg_2",
    "Segment 3": "Seg_3",
    "Segment 4": "Seg_4",
  };

  const apiSegment = segmentMap[segment] || "Select";

  useEffect(() => {
    if (view !== "Select" && apiSegment !== "Select") {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `${SERVER_URL}/payment/chart-four/${apiSegment}/${view}`
          );
          const rawData = res.data;

          const formattedData = rawData.map((item) => {
            const formattedItem = { ...item };

            if (view === "Weekly") {
              try {
                const parsedDate = parseISO(item.PaymentDate);
                if (isValid(parsedDate)) {
                  formattedItem.PaymentDate = format(parsedDate, "MM-dd");
                }
              } catch (error) {
                console.error("Date parsing error:", error);
              }
            }

            return formattedItem;
          });

          const groupedData = formattedData.reduce((acc, currentItem) => {
            const dateKey = currentItem.PaymentDate;
            if (!acc[dateKey]) {
              acc[dateKey] = { PaymentDate: dateKey };
            }
            acc[dateKey][currentItem.DPDGroup] = currentItem.TransactionAmount;
            return acc;
          }, {});

          const finalData = Object.values(groupedData);
          setData(finalData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [view, apiSegment]);

  const formatYAxisTick = (tick) => {
    return numberFormatter.format(tick);
  };

  const xAxisLabel = view === "Weekly" ? "Weekly" : "Monthly";

  return (
    <div className="recovery-distribution-chart-details">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 50,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="PaymentDate"
            angle={-45}
            textAnchor="end"
            interval={0}
            tick={{ fontSize: 10 }}
            height={60}
            label={{
              value: xAxisLabel,
              position: "insideBottom",
              offset: -10,
              style: { textAnchor: "middle", fontSize: 14 },
            }}
          />
          <YAxis
            tickFormatter={formatYAxisTick}
            tick={{ fontSize: 10 }}
            label={{
              value: "Amount Recovered",
              angle: -90,
              position: "insideLeft",
              dx: -40,
              style: { textAnchor: "middle", fontSize: 14 },
            }}
          />
          <Tooltip />
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="top"
            wrapperStyle={{
              paddingBottom: 20,
              marginLeft: 30,
            }}
          />
          <Bar dataKey="1-15" fill="#096bfd" />
          <Bar dataKey="16-30" fill="#49c3fb" />
          <Bar dataKey="31-60" fill="#003399" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RecoveryDistributionChart;
