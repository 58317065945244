// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prediction-accuracy-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1;
  padding-left: 20px;
}
.prediction-accuracy-container p {
  color: #fff;
  margin: 0;
}
.prediction-accuracy-container h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  margin-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/paymentaccuracy.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,SAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,SAAA;AACJ;AAEE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".prediction-accuracy-container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  flex: 1;\n  padding-left: 20px;\n\n  p {\n    color: #fff;\n    margin: 0;\n  }\n\n  h2 {\n    color: #fff;\n    font-size: 36px;\n    font-weight: 700;\n    margin-top: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
