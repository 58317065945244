import React, { useState } from "react";
import "./passwordInput.scss";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

const PasswordInput = ({
  name,
  id,
  placeholder,
  autoComplete,
  value,
  onChange,
}) => {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggleIcon = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  return (
    <>
      <div className="input-field">
        <input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          required
        />
        <span onClick={handleToggleIcon}>
          <Icon icon={icon} size={25} />
        </span>
      </div>
    </>
  );
};

export default PasswordInput;
