import React from 'react';
import './predictedsegment.scss'

const PredictedSegment = () => {
  return (
    <div className="predicted-segment-container">
      <p>Predicted Segment: <span>not scored</span></p>
      <h3>CB2AI™ Score</h3>
      <h1>50-70</h1>
    </div>
  );
}

export default PredictedSegment