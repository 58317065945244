import React, { useState } from "react";
import "./view.scss";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import ViewDetails from "./ViewDetails";

const View = ({view,segment}) => {
  const [toggleView, setToggleView] = useState(false);

  const handleToggle = () => {
    setToggleView(!toggleView);
  };

  return (
    <div className="view-container">
      <div className="view-controller">
        <div className="heading">
          <div className="heading-wrapper">
            <h4>predicted-segment</h4>
            <h4>payment-behavior</h4>
            <h4>payment-accuracy</h4>
          </div>
        </div>
      </div>
      {toggleView ? (
        <>
          <div className="view-details-container">
            <ViewDetails view={view} segment={segment} />
          </div>
        </>
      ) : (
        <></>
      )}
      {toggleView ? (
        <>
          <MdKeyboardDoubleArrowUp onClick={handleToggle} className="icon" />
        </>
      ) : (
        <>
          <MdKeyboardDoubleArrowDown onClick={handleToggle} className="icon" />
        </>
      )}
    </div>
  );
};

export default View;
