// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght,YTLC@0,6..12,75..125,200..1000,440..540;1,6..12,75..125,200..1000,440..540&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  scroll-behavior: smooth;
  --primary: #5271FF;
  --second: #717ff5;
  --bgColor: #fff;
  --bodyColor: #000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
  transition: all 0.3s ease-in-out;
}

body {
  display: flex;
  flex-direction: column;
  background: var(--bgColor);
  color: var(--bodyColor);
}

a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--second);
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #d1e5ff;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,0BAA0B;EAC1B,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;;;;;;EAME,SAAS;AACX;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,mBAAmB;AACrB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght,YTLC@0,6..12,75..125,200..1000,440..540;1,6..12,75..125,200..1000,440..540&display=swap\");\n\n:root {\n  scroll-behavior: smooth;\n  --primary: #5271FF;\n  --second: #717ff5;\n  --bgColor: #fff;\n  --bodyColor: #000;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Nunito Sans\", sans-serif;\n  transition: all 0.3s ease-in-out;\n}\n\nbody {\n  display: flex;\n  flex-direction: column;\n  background: var(--bgColor);\n  color: var(--bodyColor);\n}\n\na {\n  color: var(--primary);\n  text-decoration: none;\n  cursor: pointer;\n}\n\na:hover {\n  color: var(--second);\n  text-decoration: none;\n}\n\nul,\nol {\n  list-style: none;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin: 0;\n}\n\n::-webkit-scrollbar {\n  width: 7px;\n  height: 7px;\n}\n\n::-webkit-scrollbar-track {\n  background: #d1e5ff;\n}\n\n::-webkit-scrollbar-thumb {\n  background: var(--primary);\n  border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
