import {store} from "../redux/store";
import { signOutUserSuccess } from "../redux/userSlice";

window.addEventListener("beforeunload", (event) => {
  const isRefreshing = sessionStorage.getItem("isRefreshing");
  if (!isRefreshing) {
    store.dispatch(signOutUserSuccess());
  }

  sessionStorage.removeItem("isRefreshing");
});

window.addEventListener("unload", (event) => {
  sessionStorage.setItem("isRefreshing", "true");
});
