import React, { useState, useEffect } from "react";
import "./distributionbysegmentchart.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { format, parseISO, isValid } from "date-fns";
import { SERVER_URL } from "../../../utils/helper";

const DistributionBySegmentChart = ({ view, segment }) => {
  const [data, setData] = useState([]);

  const segmentMap = {
    "Segment 1": "Seg_1",
    "Segment 2": "Seg_2",
    "Segment 3": "Seg_3",
    "Segment 4": "Seg_4",
  };

  const apiSegment = segmentMap[segment] || "Select";

  useEffect(() => {
    const fetchData = async () => {
      if (view !== "Select" && apiSegment !== "Select") {
        try {
          const res = await axios.get(
            `${SERVER_URL}/payment/chart-two/${apiSegment}/${view}`
          );
          const rawData = res.data;

          const formattedData = rawData.map((item) => {
            let formattedDate = item.PaymentDate;
            if (view === "Weekly") {
              try {
                const parsedDate = parseISO(item.PaymentDate);
                if (isValid(parsedDate)) {
                  formattedDate = format(parsedDate, "MM-dd");
                }
              } catch (error) {
                console.error("Date parsing error:", error);
              }
            }
            return {
              ...item,
              PaymentDate: formattedDate,
            };
          });

          const groupedData = formattedData.reduce((acc, currentItem) => {
            const existingItem = acc.find(
              (item) => item.PaymentDate === currentItem.PaymentDate
            );
            if (existingItem) {
              existingItem.TransactionAmount += currentItem.TransactionAmount;
            } else {
              acc.push({
                PaymentDate: currentItem.PaymentDate,
                TransactionAmount: currentItem.TransactionAmount,
              });
            }
            return acc;
          }, []);

          setData(groupedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [apiSegment, view]);

  const formatYAxisTick = (tick) => {
    return `$${new Intl.NumberFormat().format(tick)}`;
  };

  const xAxisLabel = view === "Weekly" ? "Weekly" : "Monthly";

  return (
    <div className="distribution-by-chart-details">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 50,
            bottom: 25,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="PaymentDate"
            angle={-45}
            textAnchor="end"
            interval={0}
            tick={{ fontSize: 10 }}
            height={60}
            label={{
              value: xAxisLabel,
              position: "insideBottom",
              offset: -10,
              style: { textAnchor: "middle", fontSize: 14 },
            }}
          />
          <YAxis
            tickFormatter={formatYAxisTick}
            tick={{ fontSize: 10 }}
            label={{
              value: "Amount Recovered Percentage",
              angle: -90,
              position: "insideLeft",
              dx: -40,
              style: { textAnchor: "middle", fontSize: 14 },
            }}
          />
          <Tooltip />
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="top"
            wrapperStyle={{
              paddingBottom: 10,
              marginLeft: 25,
            }}
            content={({ payload }) => (
              <div className="custom-legend">
                {payload.map((entry) => (
                  <div
                    key={entry.value}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 10px",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 12,
                        height: 12,
                        backgroundColor: entry.color,
                        marginRight: 5,
                        borderRadius: 2,
                      }}
                    ></div>
                    <span>{entry.value}</span>
                  </div>
                ))}
              </div>
            )}
          />
          <Bar dataKey="TransactionAmount" fill="#096bfd" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DistributionBySegmentChart;
